<template>
  <div>
    <router-view v-slot="{ Component }">
      <transition :name="$router.customRouterData.transitionName">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { getCache, setCache } from "./utils/auth";

export default {
  name: "app",
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {},
};
</script>

<style>
#app {
  position: relative;
  width: 100%;
  height: 100%;
}

.slide_left-enter-active,
.slide_left-leave-active,
.slide_right-enter-active,
.slide_right-leave-active {
  transition: all 0.3s;
  position: absolute !important;
  background-color: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.slide_left-enter-from,
.slide_right-leave-to {
  opacity: 1;
  transform: translateX(100%);
}

.slide_right-enter-from,
.slide_left-leave-to {
  opacity: 1;
  transform: translateX(-100%);
}

.slide_left-leave-to,
.slide_right-leave-to {
  opacity: 0.3;
}
</style>
