import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/style/normalize.less";
import "./assets/style/main.less";
import {
  Button,
  Form,
  Field,
  CellGroup,
  NavBar,
  Icon,
  Tab,
  Tabs,
  Popup,
  Tabbar,
  TabbarItem,
  Cell,
  Swipe,
  SwipeItem,
  List,
  PullRefresh,
  Sticky,
  Picker,
  Radio,
} from "vant";
import { download, post, get, postJson, importFile } from "./request/http";
import "@/permission";
const components = [
  Button,
  Form,
  Field,
  CellGroup,
  NavBar,
  Icon,
  Tab,
  Tabs,
  Popup,
  Tabbar,
  TabbarItem,
  Cell,
  Swipe,
  SwipeItem,
  List,
  PullRefresh,
  Sticky,
  Picker,
  Radio,
];
const app = createApp(App);
app.config.globalProperties.$download = download;
app.config.globalProperties.$post = post;
app.config.globalProperties.$get = get;
app.config.globalProperties.$postJson = postJson;
app.config.globalProperties.$importFile = importFile;
for (const cpn of components) {
  app.component(cpn.name, cpn);
}
app.use(store).use(router).mount("#app");
