"use strict";

import axios from "axios";
import qs from "qs";
import Router from "@/router/index";
import {
  getCache,
  setCache,
  removeCache,
  setCookie,
  getCookie,
} from "@/utils/auth";
import store from "../store";
import { Notify } from "vant";

const Axios = axios.create({
  timeout: 50000,
  responseType: "json",
  withCredentials: true, // 是否允许带cookie
  baseURL: process.env.BASE_URL == "/userCenter/" ? "/api" : "",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    "x-requested-with": "XMLHttpRequest",
  },
});
// 刷新token，在最后十分钟
function refreshToken() {
  if (!getCookie("locking")) {
    console.log(getCookie("locking"));
    setCookie("locking", true);
    Axios.post("/oauth/refresh", {
      refreshToken: getCookie("refreshToken"),
    }).then((res) => {
      const ExpiresTime = new Date().getTime() + res.expiresIn * 1000;
      setCookie("token", res.accessToken);
      setCookie("expiresIn", ExpiresTime);
      setCookie("refreshToken", res.refreshToken);
      setCookie("locking", false);
    });
  }
}
Axios.interceptors.request.use(
  //请求前
  (config) => {
    if (!config.url.includes("http://")) {
      config.url = process.env.BASE_URL + "api" + config.url;
    }
    // application/x-www-form-urlencoded请求，处理一下数组对象
    if (
      config.method === "post" &&
      (config.headers["Content-Type"] === "multipart/form-data" ||
        config.headers["Content-Type"] === "application/json" ||
        config.data instanceof FormData)
    ) {
      console.log("get ", config.data.get("file"));
    } else {
      //
      let tempData = {
        ...config.data,
      };
      for (let a in tempData) {
        if (Array.isArray(tempData[a])) {
          tempData[a] = tempData[a].join(",");
        } else if (typeof tempData[a] == "object") {
          tempData[a] = JSON.stringify(tempData[a]);
        }
      }
      config.data = qs.stringify(config.data);
    }
    return config;
  },
  (error) => {
    Notify({ type: "danger", message: "请求超时" });
  }
);

Axios.interceptors.response.use(
  (res) => {
    // 是否最后十分钟
    const expiresTimeStamp =
      new Date(Number(getCookie("expiresIn"))).getTime() - new Date().getTime();
    if (expiresTimeStamp < 10 * 60 * 1000 && expiresTimeStamp > 0) {
      if (res.config.url.indexOf("/oauth/refresh") == -1) {
        refreshToken();
      }
    }
    if (res.status == 200) {
      if (res.data.size && res.data.type && !res.data.status) {
        return res;
      }
      if (res.data.status) {
        if (res.data.status == "ERROR") {
          Notify({ type: "danger", message: res.data.errorMessage });
          return Promise.reject(res.data.errorMessage);
        } else if (
          res.data.status == "未登录" ||
          res.data.status == "REDIRECT"
        ) {
          if (res.config.url == "/api/login/user") {
            return Promise.reject(res.data.errorMessage);
          } else {
            if (getCache("loginData")) {
              Axios.post("/login", {
                ...getCache("loginData"),
                avoid: "y" + abc(7),
              })
                .then((res) => {
                  window.location.replace("/personal");
                })
                .catch((error) => {
                  removeCache();
                  window.location.replace("/login");
                });
            }
          }
        } else if (res.data.status == "NO_LOGIN") {
          if (getCache("loginData")) {
            Axios.post("/login", {
              ...getCache("loginData"),
              avoid: "y" + abc(7),
            })
              .then((res) => {
                window.location.replace("/personal");
              })
              .catch((error) => {
                removeCache();
                window.location.replace("/login");
              });
          }
          return "replace";
        } else {
          if (res.data.size) {
            return res.data;
          } else {
            return res.data.result;
          }
        }
      }
    }
  },
  (error) => {
    if (error.response?.status) {
      switch (error.response.status) {
        case 401:
          Router.replace({
            path: "/login",
            query: {},
          });
          break;
        case 404:
          Notify({ type: "danger", message: "网络请求不存在" });
          break;
        case 502:
          Notify({ type: "danger", message: "后台无响应" });
          break;
        case 500:
          Notify({ type: "danger", message: "后台未启动或后台错误" });
          break;
        default:
          Notify({ type: "danger", message: error.response.data.message });
      }
      let errorInfo = error;
      return Promise.reject(errorInfo);
    } else {
      let errorInfo = error;
      return Promise.reject(errorInfo);
    }
  }
);

function download(url, data = {}, name) {
  return new Promise((resolve, reject) => {
    Axios.post(url, data, {
      responseType: "blob",
    }).then((res) => {
      let blob = new Blob([res.data]);
      let url = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      if (name) {
        link.setAttribute("download", name);
      } else if (res.headers.filename) {
        link.setAttribute("download", decodeURI(res.headers.filename));
      } else {
        link.setAttribute("download", "导出文件.xlsx");
      }
      document.body.appendChild(link);
      link.click();
      resolve(res);
    });
  });
}

function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    Axios.get(url, {
      params: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function post(url, data = {}, headers) {
  return new Promise((resolve, reject) => {
    Axios.post(url, data, headers).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function postJson(url, data = {}) {
  return new Promise((resolve, reject) => {
    Axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function importFile(url, data = {}, file, filesName = "files") {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append(filesName, file);
    for (let d in data) {
      formData.append(d, data[d]);
    }
    Axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    }).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
function abc(e) {
  e = e || 32;
  let t = "abcdefhijkmnprstwxyz2345678!@#$%^&*()",
    a = t.length,
    n = "";
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n;
}
export { importFile, download, get, post, postJson };
