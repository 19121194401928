import { getCache, setCache, removeCache } from "@/utils/auth";
import { importFile, download, get, post, postJson } from "@/request/http";
const state = {
  router: "",
};
import { constantRoutes } from "@/router";

const mutations = {
  SET_ROUTER: (state, value) => {
    state.router = value;
  },
};

const actions = {
  setRouter: function ({ commit, state }, value) {
    commit("SET_ROUTER", value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
