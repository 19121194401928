import { createRouter, createWebHistory } from "vue-router";
import transitionExtend from "./transition-extend";
const routes = [
  {
    path: "/",
    name: "home",
    redirect: "personal",
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/"),
    meta: { index: 1 },
  },
  {
    path: "/forget",
    name: "forget",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/forget"),
    meta: { index: 1 },
  },
  {
    path: "/index",
    name: "index",
    component: () => import(/* webpackChunkName: "index" */ "../views/index/"),
    meta: { index: 2 },
    children: [
      {
        path: "/personal",
        name: "personal",
        component: () =>
          import(/* webpackChunkName: "index" */ "../views/personal/"),
        meta: { index: 2, nav: 2, nav: 1 },
      },
      {
        path: "/classTimetable",
        name: "classTimetable",
        component: () =>
          import(/* webpackChunkName: "index" */ "../views/classTimetable/"),
        meta: { index: 2, nav: 2 },
      },
      {
        path: "/seeClassTimetable",
        name: "seeClassTimetable",
        component: () =>
          import(
            /* webpackChunkName: "index" */ "../views/classTimetable/see.vue"
          ),
        meta: { index: 2, nav: 1 },
      },
      {
        path: "/my",
        name: "my",
        component: () => import(/* webpackChunkName: "index" */ "../views/my/"),
        meta: { index: 2, nav: 3 },
      },
      {
        path: "/password",
        name: "password",
        component: () =>
          import(/* webpackChunkName: "index" */ "../views/my/password"),
        meta: { index: 2, nav: 3 },
      },
      {
        path: "/transferList",
        name: "transferList",
        component: () =>
          import(/* webpackChunkName: "index" */ "../views/my/transferList/"),
        meta: { index: 2, nav: 3 },
      },
      {
        path: "/substituteList",
        name: "substituteList",
        component: () =>
          import(/* webpackChunkName: "index" */ "../views/my/substituteList/"),
        meta: { index: 2, nav: 3 },
      },
    ],
  },

  {
    path: "/share",
    name: "share",
    component: () => import(/* webpackChunkName: "index" */ "../views/share"),
    meta: { index: 2 },
  },
  // {
  //   path: "/noSystem",
  //   name: "/noSystem",
  //   component: () =>
  //     import(/* webpackChunkName: "signUp" */ "../views/noSystem.vue"),
  //   meta: { index: 9 },
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default transitionExtend(router);
