import { getCache, setCache, removeCache } from "@/utils/auth";
import { importFile, download, get, post, postJson } from "@/request/http";
const state = {
  isSystem: false,
};
import { constantRoutes } from "@/router";

const mutations = {
  SET_SYSTEM: (state, value) => {
    setCache("isSystem", value);
    state.isSystem = value;
  },
};

const actions = {
  getSystem: function ({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      post("/common/check/system/switch")
        .then(async (res) => {
          commit("SET_SYSTEM", res);
          if (res) {
            resolve(res);
          } else {
            reject(error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
