import router from "./router";
import store from "./store";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getCache, removeCache, getCookie } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";
// import setting from "@/setting.js";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = [
  "login",
  "noRight",
  "noSystem",
  "error",
  "share",
  "forget",
]; // no redirect whitelist
router.beforeEach((to, from, next) => {
  NProgress.start();
  document.title = getPageTitle(to.meta.title);

  if (whiteList.includes(to.name)) {
    if (!store.getters.user.userName) {
      NProgress.done();
      next();
    } else {
      next("/index");
    }
  } else {
    if (store.getters.user.userName && getCache("userName")) {
      NProgress.done();
      next();
      // gotoRouter(to, next);
    } else if(getCache('loginData')) {
      store
        .dispatch("user/getInfo")
        .then((data) => {
          NProgress.done();
          next();
          // gotoRouter(to, next);
        })
        .catch((el) => {
          next("/login");
        });
    }else{
      next('/login');
    }
  }
});
router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
