import { getCache, setCache, removeCache } from "@/utils/auth";
import { importFile, download, get, post, postJson } from "@/request/http";
const state = {
  user: "",
};
import { Notify } from "vant";

const mutations = {
  SET_USER: (state, user) => {
    setCache("userName", user.username);
    state.user = user;
  },
};

const actions = {
  getInfo: function ({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      post("/oauth/user", {})
        .then(async (res) => {
          if (!res) {
            Notify({ type: "danger", message: "获取用户失败" });
            reject("获取用户失败");
            return false;
          } else if (res == "replace") {
            resolve(res);
          } else {
            setCache("userName", res.username);
            commit("SET_USER", res);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
