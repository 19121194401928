module.exports = {
  title: "爱排课",
  statistics: "998ed0f36aeddd3ea84aee70d2b7132f",
  // 单应用应配置虚拟目录
  virtualDirectory: "exam",
  isMulti: false,
  jump: [
    {
      role: "Admin", // 角色
      url: "/system/menu", // 跳转地址
    },
    {
      role: "SchoolManager", // 角色
      url: "/home", // 跳转地址
    },
  ],
};
