function setCache1(name, value) {
  if (typeof value == "object") {
    value = JSON.stringify(value);
  }
  return window.localStorage.setItem(name, value);
}
function getCache1(name) {
  let a = window.localStorage.getItem(name);
  if (typeof a == "object") {
    return a;
  } else {
    if (a.substring(0, 1) == "[" || a.substring(0, 1) == "{") {
      return JSON.parse(a);
    } else if (a == "undefined") {
      return undefined;
    } else {
      return a;
    }
  }
}
function removeCache1(name) {
  if (name) {
    return window.localStorage.removeItem(name);
  } else {
    return window.localStorage.clear();
  }
}

function getCookie1(name) {
  let arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");

  if ((arr = document.cookie.match(reg))) return decodeURIComponent(arr[2]);
  else return null;
}

function setCookie1(name, value) {
  //定义两小时
  let hour = 2;
  let exp = new Date();
  //定义的失效时间，
  exp.setTime(exp.getTime() + hour * 60 * 60 * 1000);
  //写入Cookie  ，toGMTstring将时间转换成字符串。
  document.cookie =
    name + "=" + encodeURIComponent(value) + ";expires=" + exp.toGMTString;
}
function removeCookie1() {
  let keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (let i = keys.length; i--; )
      document.cookie = keys[i] + "=0;expires=" + new Date(0).toUTCString();
  }
}
export const dispatchEventStroage = () => {
  const signSetItem = localStorage.setItem;
  localStorage.setItem = function (key, val) {
    let setEvent = new Event("setItemEvent");
    setEvent.key = key;
    setEvent.newValue = val;
    window.dispatchEvent(setEvent);
    signSetItem.apply(this, arguments);
  };
};
export function getCache(name) {
  return getCache1(name) || getCookie1(name);
}
export function setCache(name, value) {
  setCache1(name, value) && setCookie1(name, value);
}
export function getCookie(name) {
  return getCookie1(name) || getCache1(name);
}
export function setCookie(name, value) {
  setCache1(name, value) && setCookie1(name, value);
}
export function removeCache(name) {
  removeCache1(name) && removeCookie1(name);
}
